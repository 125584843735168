<template>
  <v-container grid-list-xl fluid>
    <v-row justify="center">
      <v-col
        v-for="organization in organizations"
        :key="organization.id"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card
          outlined
          height="150"
          @click="selectOrganization(organization.id)"
        >
          <v-card-title class="flex-column align-center justify-center fill-height">
            {{ organization.name }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card
          outlined
          height="150"
          :to="{ name: 'organizations-new' }"
        >
          <v-card-title class="flex-column align-center justify-center fill-height">
            <v-avatar>
              <v-icon large>
                mdi-plus
              </v-icon>
            </v-avatar>
            New Organization
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Organizations',
  computed: {
    ...mapState(['organizations'])
  },
  beforeMount() {
    if (this.$store.state.organizationId) {
      this.$store.dispatch('setOrganizationId', undefined)
    }
  },
  methods: {
    selectOrganization(organizationId) {
      this.$store.dispatch('setOrganizationId', organizationId)
      this.$router.push({ name: 'index' })
    }
  }
}
</script>
